@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

body {
  font-family: "Arial", serif !important;
  /* TODO: change font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: var(--main-background-image);
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

html {
  scroll-behavior: smooth;
}



* {
  margin: 0;
  padding: 0;
  cursor: auto;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #FFBBEC;
  background-image: url(../src/Assets/bars.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c05ab9cb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(174, 64, 224, 0.65);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.911);
  border-radius: 12px;
}
